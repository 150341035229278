import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
const NavBar = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const triggerScroll = () => {
      window.scrollTo(0, 0);
    };
    //fix for top of page navigation in aboutUs page
    setTimeout(triggerScroll, 100);
  }, [pathname]);

  return (
    <nav className="navBar">
      <div className="linksContainer">
        <Link
          to="/"
          className={pathname === "/" ? "navLinks active" : "navLinks"}
        >
          {" "}
          Home{" "}
        </Link>
        <Link
          to="/aboutUs"
          className={pathname === "/aboutUs" ? "navLinks active" : "navLinks"}
        >
          {" "}
          About Us{" "}
        </Link>
        <Link
          to="/testimonials"
          className={
            pathname === "/testimonials" ? "navLinks active" : "navLinks"
          }
        >
          {" "}
          Testimonials{" "}
        </Link>
        <Link
          to="/gallery"
          className={pathname === "/gallery" ? "navLinks active" : "navLinks"}
        >
          Gallery
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;
