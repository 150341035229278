import { Swiper, SwiperSlide } from "swiper/react";
import "./Testimonials.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import { Navigation, EffectCards } from "swiper";

import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";

const Testimonials = () => (
  <div className="testimonials">
    <h2 className="testimonialHeader"> Testimonials </h2>
    <Swiper
      navigation={true}
      effect={"cards"}
      modules={[Navigation, EffectCards]}
      grabCursor={true}
      className="myTestimonials"
      loop={false}
      cardsEffect={{
        slideShadows: false,
      }}
    >
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderOrange" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              I contacted Jeff after a recommendation from a neighbour. I am so
              glad that I did. The price was good and the quality of the finish
              is of a very high standard. Jeff's attention to detail is second
              to none and the job was completed quickly. The installation of our
              patio cover was completed efficiently our deck was left spotlessly
              clean after the work was completed. I will definitely recommend
              Primeline to potential customers.
              <figcaption>— Andy Joyce </figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderYellow" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes innerReviewQuotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              Jeff was quick to respond to our request for a quote for replacing
              our Aluminum patio cover. He was definitely competitive and
              listened to my wishes on this replacement. Jeff scheduled a date
              to install and arrived on time and completed the work in one day.
              They cleaned up after and the patio cover looked spectacular. I
              asked to have an additional I Beam installed to mount an awning
              and he installed at a later date. Great job Jeff.
              <figcaption>— Murray Day </figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes innerReviewQuotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderOrange" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              We called Jeff for a quote and arrived that afternoon. He said it
              would be about 2 weeks before he could install our canopy, but it
              was actually sooner. The work was done professionally with great
              respect for our property. The final result has made us very happy
              and I would highly recommend this company.
              <figcaption>— Bob Pennington </figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderYellow" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes innerReviewQuotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              After expanding our living space with a new backyard deck, we
              chose Primeline Industries to build a patio cover with bronze
              acylight panels. We are extremely pleased with the investment as
              it has allowed us to use our deck 12 months of the year, even
              through the dark, rainy days of the west coast winter! The build
              was fast and high quality. Jeff even went the extra mile to
              install some flashing to block 'overflow' from our gutters and our
              deck and patio furniture stays 100% dry even on the rainiest of
              days.
              <figcaption>— Shane Sauer </figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes innerReviewQuotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderOrange" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              Jeff and his wife just completed a cover and railings on our deck.
              Jeff recognized any potential issues with the project and was
              quick to find a solution. We were pleased that the estimate was
              done by Jeff himself and that he was also the installer. The job
              was completed efficiently and everything was clean when they left.
              We would and have already recommend Primeline to potential
              customers.
              <figcaption>— Elaine McLeod</figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderYellow" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes innerReviewQuotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              I contacted Jeff for a quote on my patio cover. His price was
              competitive and the turn around time was reasonable. The style of
              the cover I had installed wasn't one he usually does but the end
              product turned out great. Jeff and his wife made sure there were
              no details overlooked. I would highly recommend Primeline
              Industries and will be using them for future projects.
              <figcaption>— Jordan Pawliuk </figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes innerReviewQuotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderOrange" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              I can highly recommend this company for their excellent
              workmanship and installation knowledge, their finished installed
              product will speak for itself.
              <figcaption>— Norbert Bluhm </figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonialContent">
          <div className="ReviewHeaderYellow" />
          <div className="testimonialContentWrapper">
            <div className="testQuotesLeftContainer">
              <ImQuotesLeft className="quotes innerReviewQuotes" />
            </div>
            <blockquote className="testimonialQuote">
              {" "}
              We just had a deck cover and railings installed by Jeff and his
              partner. Excellent service and quality of work was top notch. We
              couldn't be happier with the end result.
              <figcaption>— Dennis H </figcaption>
            </blockquote>
            <div className="testQuotesRightContainer">
              <ImQuotesRight className="quotes innerReviewQuotes" />
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
);

export default Testimonials;
