import axios from "axios";
import React, { useState } from "react";
import "./QuoteForm.css";

const QuoteForm = ({ setIsActive }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [details, setDetails] = useState("");
  const [botCheck, setBotCheck] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (botCheck) {
          return;
        }
        const formBody = {
          name,
          email,
          phone,
          street,
          postalCode,
          city,
          details,
          access_key: "6968f820-082f-44d7-b903-e11a3fffa917",
        };
        setIsActive(true);
        axios
          .post("https://api.web3forms.com/submit", formBody)
          .then(() => {
            setIsActive(false);
            window.location.href = "https://web3forms.com/success";
          })
          .catch(() => {
            setIsActive(false);
          });
      }}
    >
      <label htmlFor="name">
        {" "}
        Name <span className="required">* </span>
      </label>
      <input
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <label htmlFor="email">
        {" "}
        Email <span className="required"> * </span>{" "}
      </label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <label htmlFor="phone">
        {" "}
        Phone Number <span className="required"> * </span>{" "}
      </label>
      <input
        type="phone"
        id="phone"
        name="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
      />
      <label htmlFor="street"> Street </label>
      <input
        type="text"
        id="street"
        name="street"
        value={street}
        onChange={(e) => setStreet(e.target.value)}
      />
      <label htmlFor="postal code"> Postal Code </label>
      <input
        type="text"
        id="postal code"
        name="postal code"
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
      />
      <label htmlFor="city">
        {" "}
        City <span className="required"> * </span>{" "}
      </label>
      <input
        type="text"
        id="city"
        name="city"
        list="city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        required
      />
      <datalist id="city">
        <option value="Abbotsford"> </option>
        <option value="Burnaby"> </option>
        <option value="Chilliwack"> </option>
        <option value="Coquitlam"> </option>
        <option value="Delta"> </option>
        <option value="Langley"> </option>
        <option value="Maple Ridge"> </option>
        <option value="Mission"> </option>
        <option value="New Westminister"> </option>
        <option value="Pitt Meadows"> </option>
        <option value="Port Coquitlam"> </option>
        <option value="Port Moody"> </option>
        <option value="Richmond"> </option>
        <option value="Surrey"> </option>
        <option value="Vancouver"> </option>
        <option value="White Rock"> </option>
      </datalist>
      <label htmlFor="details"> Details of Project</label>
      <textarea
        name="message"
        id="details"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
      ></textarea>
      <input
        type="checkbox"
        name="botcheck"
        className="hidden"
        style={{ display: "none" }}
        onChange={(e) => setBotCheck(true)}
      />
      <input type="submit" value="Submit" className="formSubmit" />
    </form>
  );
};

export default QuoteForm;
