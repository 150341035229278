import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./MainContent.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { BsPeopleFill } from "react-icons/bs";
import { FaMedal } from "react-icons/fa";
import { IoMdThumbsUp } from "react-icons/io";
import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";

import { Navigation, Autoplay, EffectFade } from "swiper";
import "../../CustomSwiper.css"; // css to override Swiper css

import pic1a from "../../Assets/alum1.jpg";
import pic2a from "../../Assets/alum2.jpg";
import pic3a from "../../Assets/alum3.jpg";
import pic4a from "../../Assets/alum4.jpg";
import pic5a from "../../Assets/alum5.jpg";
import pic6a from "../../Assets/alum6.jpg";
import pic7a from "../../Assets/alum7.jpg";
import pic8a from "../../Assets/alum8.jpg";
import pic9a from "../../Assets/alum9.jpg";
import pic10a from "../../Assets/alum10.jpg";
import pic11a from "../../Assets/alum11.jpg";

import pic1g from "../../Assets/glass1.jpg";
import pic2g from "../../Assets/glass2.jpg";
import pic3g from "../../Assets/glass3.jpg";
import pic4g from "../../Assets/glass4.jpg";
import pic5g from "../../Assets/glass5.jpg";
import pic6g from "../../Assets/glass6.jpg";
import pic7g from "../../Assets/glass7.jpg";
import pic8g from "../../Assets/glass8.jpg";

import pic1r from "../../Assets/rail1.jpg";
import pic2r from "../../Assets/rail2.jpg";
import pic3r from "../../Assets/rail3.jpg";
import pic4r from "../../Assets/rail4.jpg";
import pic5r from "../../Assets/rail5.jpg";
import pic6r from "../../Assets/rail6.jpg";
import pic7r from "../../Assets/rail7.jpg";
import pic10r from "../../Assets/rail10.jpg";
import pic11r from "../../Assets/rail11.jpg";
import pic12r from "../../Assets/rail12.jpg";
import { Link } from "react-router-dom";

const MainContent = () => {
  return (
    <div className="mainContent">
      <div className="description">
        <h1> We install patio covers </h1>
        <h6> Aluminum Covers, Glass Covers and Aluminum Railings </h6>
        <p className="caption">
          {" "}
          Family run business serving the Lower Mainland in BC, Canada since
          1995
        </p>
      </div>

      <div className="productContainer">
        <div className="carouselContainer">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            autoplay={{
              delay: 4000,
              disabledonInteraction: true,
            }}
            effect={"fade"}
            modules={[Navigation, Autoplay, EffectFade]}
            className="mySwiper"
            loop={true}
          >
            <SwiperSlide>
              <img
                src={pic1a}
                alt="Aluminum canopy with skylights with white panels and black trim."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic2a}
                alt=" Aluminum canopy with single skylight with white panels and white trim."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic3a}
                alt="Aluminum canopy no skylights with black trim and white panels."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic4a}
                alt="Aluminum canopy with three skylights over backdoor with white panels, black trim and white posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic5a}
                alt="Aluminum canopy with skylights with white panels, black trim, and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic6a}
                alt="Aluminum canopy with skylights with white panels, white trim, and white posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic7a}
                alt="Aluminum canopy with white panels, black trim, and black posts. "
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic8a}
                alt="Aluminum canopy no skylights with white panels, black trim, and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic9a}
                alt="Aluminum canopy with skylights for outdoor barbeque with white panels, black trim and white posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic10a}
                alt="Aluminum canopy with skylights for outdoor motorcycle coverage. White panels, white trim, and white posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic11a}
                alt="Aluminum canopy with skylights with white panels, white trim, and whtie posts."
                className="productPics"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="productTextContainer">
          <h2> Aluminum Canopy Covers </h2>
          <p className="productDescription">
            Aluminum patio covers extend years of life to your deck while giving
            year around use, which means no more barbecuing in the rain! Our
            high quality engineered aluminum patio covers and carport covers
            offer superior outdoor living with a low maintenance design. With
            either a solid v pan design or with optional 2 ft wide skylights
            using SPD acrylic or tempered glass, available in tinted or clear,
            we build and install all our patio covers to withstand the snow
            falls we receive in the Mission, Chilliwack, and Abbotsford area.
            Offering exceptional value, no subcontracting, we are there from
            start to finish insuring your project is done right and on time as
            quoted.
          </p>
          {/* <Link to="/gallery" className="morePicturesButton">
            {" "}
            See More Projects...{" "}
          </Link> */}
        </div>
      </div>
      <div className="productContainer product-alt">
        <div className="productTextContainer product-alt">
          <h2> Glass Patio Covers </h2>
          <p className="productDescription product-alt">
            Aluminum with tempered glass patio covers, manufactured and
            engineered to complement your home with lifetime finished aluminum
            products. Primeline Industries can custom build your glass cover
            with a combination of wood, cedar, fir and other aluminium-wood
            style products to match your home or design features of your outdoor
            living space. Glass panels are available either tinted to reduce
            expose to sun or clear for unobstructed view. Optional acrylic
            panels are available in clear, bronze tint and cool blue to further
            reduce heat transfer from intense southern exposure, as well as
            offering more privacy while still maintaining maximum amount of
            light.
          </p>
        </div>
        <div className="carouselContainer product-alt">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            autoplay={{
              delay: 4000,
              disabledonInteraction: true,
            }}
            effect={"fade"}
            modules={[Navigation, Autoplay, EffectFade]}
            className="mySwiper"
            loop={true}
          >
            <SwiperSlide>
              <img
                src={pic1g}
                alt="Glass patio cover with black trim and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic2g}
                alt="Glass patio cover with black trim and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic3g}
                alt="Glass patio cover with black trim and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic4g}
                alt="Glass patio cover with black trim and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic5g}
                alt="Glass patio cover with black trim and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic6g}
                alt="Glass patio cover with black trim and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic7g}
                alt="Glass patio cover with black trim and black posts."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic8g}
                alt="Glass patio cover with white trim and white posts."
                className="productPics"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="productContainer">
        <div className="carouselContainer">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            autoplay={{
              delay: 4000,
              disabledonInteraction: true,
            }}
            effect={"fade"}
            modules={[Navigation, Autoplay, EffectFade]}
            className="mySwiper"
            loop={true}
          >
            <SwiperSlide>
              <img
                src={pic1r}
                alt="Glass railings with black trim at front side of deck and black aluminum railings at back side of deck and for staircase."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic2r}
                alt="Glass railings with brown trim to complement log cabin home."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic3r}
                alt="Black aluminum railings."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic5r}
                alt="Glass railings with white trim."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic6r}
                alt="Black aluminum railings on curved deck."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic7r}
                alt="Glass railings with white trim."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic4r}
                alt="White aluminum railings on deck and staircase."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic10r}
                alt="Black aluminum railings for balcony."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic11r}
                alt="Glass railings with white trim for balcony."
                className="productPics"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={pic12r}
                alt="Black aluminum railings."
                className="productPics"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="productTextContainer">
          <h2> Aluminum Railings </h2>
          <p className="productDescription">
            Primeline Industries offers low maintenance, high quality engineered
            powder coated Vista Aluminum Railings in standard picket style or
            wide picket, as well as glass railings. Primeline can build or
            modify your deck or patio to suit your needs and compliment your
            home, using a variety of materials such as wood, composite, or
            aluminum, or simply repair your deck and rebuild your stairs. Give
            us a call for all your custom deck projects.
          </p>
        </div>
      </div>

      <div className="whyChooseUsContainer">
        <h2 className="choose"> Why Choose Us? </h2>
        <div className="bubbleValuesContainer">
          <div className="bubble orange">
            <div className="valueWrapper">
              <IoMdThumbsUp className="valueIcon" />
            </div>
            <h3>
              We built our reputation on customer satisfaction, integrity and
              good value{" "}
            </h3>
          </div>
          <div className="bubble yellow">
            <div className="valueWrapper">
              <FaMedal className="valueIconYellow" />
            </div>
            <h3>
              Our products are installed to the highest standards with top
              quality manufactured products{" "}
            </h3>
          </div>
          <div className="bubble orange">
            <div className="valueWrapper">
              <BsPeopleFill className="valueIcon" />
            </div>
            <h3>
              We are a family run business. No subcontracting. Exceptional Value
            </h3>
          </div>
        </div>
        <h3 className="blue">
          {" "}
          We are there from start to finish making sure your project is done
          right and on time as quoted{" "}
        </h3>
      </div>

      <div className="reviewSection">
        <h2>
          {" "}
          See what our <span className="orangeHighlight">clients </span> have to
          say:{" "}
        </h2>
        <div className="featuredReviews">
          <div className="outerReview">
            <div className="outerReviewHeader" />
            <div className="outerReviewContent">
              <div className="quotesLeftContainer">
                <ImQuotesLeft className="quotes" />
              </div>
              <blockquote>
                {" "}
                We just had a deck cover and railings installed by Jeff and his
                partner. Excellent service and quality of work was top notch. We
                couldn't be happier with the end result.
                <figcaption>— Dennis H </figcaption>
              </blockquote>
              <div className="quotesRightContainer">
                <ImQuotesRight className="quotes" />
              </div>
            </div>
          </div>
          <div className="innerReview">
            <div className="innerReviewHeader" />
            <div className="innerReviewContent">
              <div className="quotesLeftContainer">
                <ImQuotesLeft className="quotes innerReviewQuotes" />
              </div>
              <blockquote>
                After expanding our living space with a new backyard deck, we
                chose Primeline Industries to build a patio cover with bronze
                acylight panels. We are extremely pleased with the investment as
                it has allowed us to use our deck 12 months of the year, even
                through the dark, rainy days of the west coast winter! The build
                was fast and high quality. Jeff even went the extra mile to
                install some flashing to block 'overflow' from our gutters and
                our deck and patio furniture stays 100% dry even on the rainiest
                of days.
                <figcaption>— Shane Sauer </figcaption>
              </blockquote>
              <div className="quotesRightContainer">
                <ImQuotesRight className="quotes innerReviewQuotes" />
              </div>
            </div>
          </div>

          <div className="outerReview">
            <div className="outerReviewHeader" />
            <div className="outerReviewContent">
              <div className="quotesLeftContainer">
                <ImQuotesLeft className="quotes" />
              </div>{" "}
              <blockquote>
                Jeff and his wife just completed a cover and railings on our
                deck. Jeff recognized any potential issues with the project and
                was quick to find a solution. We were pleased that the estimate
                was done by Jeff himself and that he was also the installer. The
                job was completed efficiently and everything was clean when they
                left. We would and have already recommend Primeline to potential
                customers.
                <figcaption>— Elaine McLeod </figcaption>
              </blockquote>
              <div className="quotesRightContainer">
                <ImQuotesRight className="quotes" />
              </div>{" "}
            </div>{" "}
          </div>
        </div>
        <div className="buttonContainer">
          <Link to="/testimonials" className="moreReviewsButton">
            {" "}
            Read More Reviews...{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
