import { useState } from "react";
import Modal from "react-modal";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";

import { Banner } from "./components/Banner";
import { Footer } from "./components/Footer";
import { MainContent } from "./components/MainContent";
import { QuoteForm } from "./components/QuoteForm";
import { AboutPage } from "./components/AboutPage";
import { Testimonials } from "./components/Testimonials";
import { Gallery } from "./components/Gallery";
import { MdClear } from "react-icons/md";
import { BsTelephoneForwardFill } from "react-icons/bs";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { setIsModalOpen } from "./store";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    height: "80%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    padding: 0,
  },
  overlay: {
    zIndex: 99,
  },
};

Modal.setAppElement("#root");

function App() {
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const { isModalOpen } = useSelector((state) => {
    return state.modal;
  });

  return (
    <Router>
      <div className="App">
        <Banner /> {/* this is prop to QuoteForm */}
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/aboutUs" element={<AboutPage />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => dispatch(setIsModalOpen(false))}
          style={customStyles}
        >
          <LoadingOverlay
            active={isActive}
            spinner
            classNamePrefix="formOverlay"
          >
            <div
              className={isActive ? "modalBlur modalContent" : "modalContent"}
            >
              <div className="modalHeaderContainer">
                <MdClear
                  className="modalCloseButton"
                  onClick={() => dispatch(setIsModalOpen(false))}
                />
                <h2 className="modalFormTitle">
                  <a href="tel:604-467-4417" className="phoneContainer">
                    <div className="phoneContainer">
                      <BsTelephoneForwardFill />{" "}
                      <span className="phoneNumber "> 604-467-4417</span>
                    </div>
                  </a>
                </h2>
                <h2 className="modalFormTitle small"> Or </h2>
                <h2 className="modalFormTitle"> Request a Free Quote </h2>
              </div>
              <QuoteForm setIsActive={setIsActive} isModalOpen={isModalOpen} />
              {/* sending prop to QuoteForm */}
            </div>
          </LoadingOverlay>
        </Modal>
      </div>
    </Router>
  );
}

export default App;
