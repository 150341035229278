import { configureStore } from "@reduxjs/toolkit";
import { modalReducer, setIsModalOpen } from "./slices/modalSlice";
import {
  mobileMenuReducer,
  setIsMobileMenuOpen,
} from "./slices/mobileMenuSlice";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    mobileMenu: mobileMenuReducer,
  },
});

export { store };
export { setIsModalOpen, setIsMobileMenuOpen };
