import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "./Gallery.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";

import {
  FreeMode,
  Navigation,
  Thumbs,
  Autoplay,
  EffectFade,
  Pagination,
} from "swiper";

import gallPic1a from "../../Assets/alumgallery1.jpg";
import gallPic2a from "../../Assets/alumgallery2.jpg";
import gallPic3a from "../../Assets/alumgallery3.jpg";
import gallPic4a from "../../Assets/alumgallery4.jpg";
import gallPic5a from "../../Assets/alumgallery5.jpg";
import gallPic6a from "../../Assets/alumgallery6.jpg";
import gallPic7a from "../../Assets/alumgallery7.jpg";
import gallPic8a from "../../Assets/alumgallery8.jpg";
import gallPic9a from "../../Assets/alumgallery9.jpg";
import gallPic10a from "../../Assets/alumgallery10.jpg";
import gallPic11a from "../../Assets/alumgallery11.jpg";
import gallPic12a from "../../Assets/alumgallery12.jpg";
import gallPic13a from "../../Assets/alumgallery13.jpg";
import gallPic14a from "../../Assets/alumgallery14.jpg";
import gallPic15a from "../../Assets/alumgallery15.jpg";
import gallPic16a from "../../Assets/alumgallery16.jpg";
import gallPic17a from "../../Assets/alumgallery17.jpg";

import featPic1a from "../../Assets/alum1.jpg";
import featPic2a from "../../Assets/alum2.jpg";
import featPic3a from "../../Assets/alum3.jpg";
import featPic4a from "../../Assets/alum4.jpg";
import featPic5a from "../../Assets/alum5.jpg";
import featPic6a from "../../Assets/alum6.jpg";
import featPic7a from "../../Assets/alum7.jpg";
import featPic8a from "../../Assets/alum8.jpg";
import featPic9a from "../../Assets/alum9.jpg";
import featPic10a from "../../Assets/alum10.jpg";
import featPic11a from "../../Assets/alum11.jpg";

import pic1g from "../../Assets/glass1.jpg";
import pic2g from "../../Assets/glass2.jpg";
import pic3g from "../../Assets/glass3.jpg";
import pic4g from "../../Assets/glass4.jpg";
import pic5g from "../../Assets/glass5.jpg";
import pic6g from "../../Assets/glass6.jpg";
import pic7g from "../../Assets/glass7.jpg";
import pic8g from "../../Assets/glass8.jpg";

import gallPic1g from "../../Assets/glassgallery1.jpg";
import gallPic2g from "../../Assets/glassgallery2.jpg";
import gallPic3g from "../../Assets/glassgallery3.jpg";
import gallPic4g from "../../Assets/glassgallery4.jpg";
import gallPic5g from "../../Assets/glassgallery5.jpg";
import gallPic6g from "../../Assets/glassgallery6.jpg";
import gallPic7g from "../../Assets/glassgallery7.jpg";

import pic1r from "../../Assets/rail1.jpg";
import pic2r from "../../Assets/rail2.jpg";
import pic3r from "../../Assets/rail3.jpg";
import pic4r from "../../Assets/rail4.jpg";
import pic5r from "../../Assets/rail5.jpg";
import pic6r from "../../Assets/rail6.jpg";
import pic7r from "../../Assets/rail7.jpg";
import pic10r from "../../Assets/rail10.jpg";
import pic11r from "../../Assets/rail11.jpg";
import pic12r from "../../Assets/rail12.jpg";

import gallPic1r from "../../Assets/railgallery1.jpg";
import gallPic2r from "../../Assets/railgallery2.jpg";
import gallPic3r from "../../Assets/railgallery3.jpg";
import gallPic4r from "../../Assets/railgallery4.jpg";
import gallPic5r from "../../Assets/railgallery5.jpg";
import gallPic6r from "../../Assets/railgallery6.jpg";
import gallPic7r from "../../Assets/railgallery7.jpg";
import gallPic8r from "../../Assets/railgallery8.jpg";

const galleryTypes = {
  ALUM: "alumn",
  GLASS: "glass",
  ALUM_RAILS: "alumninum rails",
};

const aluCovers = {
  pictures: [
    gallPic1a,
    gallPic2a,
    featPic1a,
    featPic2a,
    gallPic3a,
    gallPic4a,
    featPic3a,
    featPic4a,
    gallPic5a,
    gallPic6a,
    featPic5a,
    featPic6a,
    gallPic7a,
    gallPic8a,
    featPic7a,
    featPic8a,
    gallPic9a,
    gallPic10a,
    featPic9a,
    featPic10a,
    gallPic11a,
    gallPic12a,
    featPic11a,
    gallPic13a,
    gallPic14a,
    gallPic15a,
    gallPic16a,
    gallPic17a,
  ],
  type: galleryTypes.ALUM,
};
const glassCovers = {
  pictures: [
    pic1g,
    pic2g,
    pic3g,
    pic4g,
    pic5g,
    pic6g,
    pic7g,
    pic8g,
    gallPic1g,
    gallPic2g,
    gallPic3g,
    gallPic4g,
    gallPic5g,
    gallPic6g,
    gallPic7g,
  ],
  type: galleryTypes.GLASS,
};
const alumRails = {
  pictures: [
    pic1r,
    pic2r,
    pic3r,
    pic4r,
    pic5r,
    pic6r,
    pic7r,
    pic10r,
    pic11r,
    pic12r,
    gallPic1r,
    gallPic2r,
    gallPic3r,
    gallPic4r,
    gallPic5r,
    gallPic6r,
    gallPic7r,
    gallPic8r,
  ],
  type: galleryTypes.ALUM_RAILS,
};

const Gallery = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [galleryList, setGalleryList] = useState(aluCovers);

  return (
    <div className="gallery">
      <h2 className="galleryHeader"> Gallery </h2>
      <div className="gallerySelectorWrapper">
        <div className="gallerySelectorContainer">
          <button
            className={
              galleryList.type === galleryTypes.ALUM
                ? "gallerySelector active"
                : "gallerySelector"
            }
            onClick={() => setGalleryList(aluCovers)}
          >
            {" "}
            Aluminum Covers{" "}
          </button>
          <button
            className={
              galleryList.type === galleryTypes.GLASS
                ? "gallerySelector active"
                : "gallerySelector"
            }
            onClick={() => setGalleryList(glassCovers)}
          >
            {" "}
            Glass Covers{" "}
          </button>
          <button
            className={
              galleryList.type === galleryTypes.ALUM_RAILS
                ? "gallerySelector active"
                : "gallerySelector"
            }
            onClick={() => setGalleryList(alumRails)}
          >
            {" "}
            Aluminum Railings{" "}
          </button>
        </div>
      </div>

      <div className="galleryContainer">
        <div className="galleryPicContainer">
          <Swiper
            spaceBetween={10}
            navigation={true}
            autoplay={{
              delay: 4000,
              disabledonInteraction: true,
            }}
            pagination={{ type: "fraction" }}
            thumbs={{ swiper: thumbsSwiper }}
            effect={"fade"}
            modules={[
              FreeMode,
              Navigation,
              Thumbs,
              Autoplay,
              EffectFade,
              Pagination,
            ]}
            className="mySwiper2"
            loop={true}
          >
            {galleryList?.pictures?.map((val) => {
              return (
                <SwiperSlide>
                  <img src={val} alt="" key={val} className="galleryPic" />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Thumbs, Navigation]}
            className="mySwiper"
          >
            {galleryList?.pictures?.map((val) => {
              return (
                <SwiperSlide>
                  <img src={val} alt="" key={val} className="galleryPic" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
