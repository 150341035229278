import { createSlice } from "@reduxjs/toolkit";

const mobileMenuSlice = createSlice({
  name: "mobileMenu",
  initialState: {
    isMobileMenuOpen: false,
  },
  reducers: {
    setIsMobileMenuOpen(state, action) {
      state.isMobileMenuOpen = action.payload;
    },
  },
});

export const { setIsMobileMenuOpen } = mobileMenuSlice.actions;
export const mobileMenuReducer = mobileMenuSlice.reducer;
