import logo from "../../Assets/Primeline Logo with Name.svg";
import "./Banner.css";
import { HiMenu } from "react-icons/hi";
import { MdClear } from "react-icons/md";
import { NavBar } from "../NavBar";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { useDispatch, useSelector } from "react-redux";
import { setIsModalOpen } from "../../store";
import { setIsMobileMenuOpen } from "../../store";

const Banner = () => {
  const dispatch = useDispatch();

  const { isMobileMenuOpen } = useSelector((state) => {
    return state.mobileMenu;
  });

  const handleMobileClose = () => {
    dispatch(setIsMobileMenuOpen(false));
  };

  return (
    <div className="banner">
      <Link to="/" className="logoLink">
        {" "}
        <img src={logo} alt="company  logo" className="logo" />{" "}
      </Link>
      <NavBar />
      <button
        className="quoteButton"
        onClick={() => dispatch(setIsModalOpen(true))}
      >
        {" "}
        Request Quote{" "}
      </button>
      <div className="mobileMenuContainer">
        <Menu
          className="mobileMenu"
          customBurgerIcon={<HiMenu />}
          customCrossIcon={<MdClear />}
          isOpen={isMobileMenuOpen}
          onOpen={() => dispatch(setIsMobileMenuOpen(true))}
          onClose={handleMobileClose}
        >
          <Link to="/" className="navLinks" onClick={handleMobileClose}>
            Home
          </Link>
          <Link to="/aboutUs" className="navLinks" onClick={handleMobileClose}>
            {" "}
            About Us{" "}
          </Link>
          <Link
            to="/testimonials"
            className="navLinks"
            onClick={handleMobileClose}
          >
            {" "}
            Testimonials{" "}
          </Link>
          <Link to="/gallery" className="navLinks" onClick={handleMobileClose}>
            {" "}
            Gallery{" "}
          </Link>
        </Menu>
      </div>
    </div>
  );
};

export default Banner;
