import picAbout from "../../Assets/coverPhoto.jpg";
import iconInstagram from "../../Assets/iconInstagram.svg";

import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="aboutUs">
      <div className="pictureContainer">
        <img
          src={picAbout}
          alt="Aluminum Canopy with railings."
          className="aboutPic"
        />
        <h2 className="pictureCaption">
          {" "}
          Making your outdoor living space a better place since 1995
        </h2>
      </div>

      <div className="aboutContentWrapper">
        <p className=" aboutText outerAboutContent">
          {" "}
          <span className="jeff">Jeff </span> and{" "}
          <span className="cor">Cor</span>, husband and wife duo, have been
          running and operating Primeline Industries located in Maple Ridge, BC
          since 1995. We are a family run business that doesn't believe in
          subcontractors. From start to finish, you can count on us to get your
          project done on time and right the first time around.{" "}
        </p>
      </div>
      <div className="aboutContentWrapper">
        <p className="aboutText innerAboutContent">
          We specialize in sales and installation of aluminum patio covers,
          glass canopies, deck renovations or repairs, aluminum railings, and
          retractable fabric awnings. Our mission has always been focused on
          customer satisfaction, good value and integrity, all of which have
          contributed to our continued success for over 25 years from referrals
          and repeat customers. Give us a call at{" "}
          <span className="number">604-467-4417 </span> or request a quote
          online! We look forward to working on your cover project!
        </p>
      </div>
      <div className="aboutContentWrapper">
        <div className="aboutText outerAboutContent instaContent">
          <p> Check us out on Instagram!</p>
          <a
            href="https://www.instagram.com/patiocovers/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={iconInstagram} alt="" className="instaIcon" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
